import React from 'react';
import { Handle } from "reactflow";

const CustomTargetNode = ({ data }) => {
  return (
    <div style={{ textAlign: 'center', position: 'relative', padding: '10px', border: '1px solid black', borderRadius: '4px' }}>
      <img src="/flow.svg" alt="node" style={{ width: '50px', height: '50px', borderRadius: '4px' }} />

      <Handle
        type="target"
        position="left"
        style={{
          top: '50%',
          left: '-5px',
          background: 'black',
          width: '10px', 
          height: '10px',
          borderRadius: '50%',
          transform: 'translateY(-50%)'
        }}
      />
      <div style={{
        position: 'absolute',
        bottom: '-26px',
        left: '50%',
        transform: 'translateX(-50%)',
        whiteSpace: 'nowrap'
      }}>
        {data.label}
      </div>
    </div>
  );
};

export default CustomTargetNode;
