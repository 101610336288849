import { useCallback, useEffect, useState, Fragment } from "react";
import axios from "axios";
import useNotifications from "../../../hooks/useNotifications";
import get from 'lodash/get';
import filter from 'lodash/filter';
import { renderErrors } from "../../../common/formHelpers";
import HeaderBuilder from "../../../components/HeaderBuilder";
import { IconPlus, IconSearch } from "@tabler/icons-react";
import ItemSelectorModal from "../../../components/ItemSelectorModal";
import Modal from "../../../components/Modal";
import NewConfiguration from "../../Configuration/NewConfiguration";
import { generateWorkspacePath } from "../../../common/urlHelpers";

const CreateSource = (props) => {
    const { onCancel, onSuccess, noShadow = false } = props;
    const { addNotification } = useNotifications();

    const [name, setName] = useState('');
    const [secured, setSecured] = useState(true);
    const [active, setActive] = useState(true);
    const [executing, setExecuting] = useState(false);
    const [error, setError] = useState({ message: null, details: [] });
    const [headers, setHeaders] = useState([{ key: "", value: "" }]);
    const [captureRemoteIP, setCaptureRemoteIP] = useState(false);
    const [configuration, setConfiguration] = useState(null);
    const [showConfigurationSelector, setShowConfigurationSelector] = useState(false);
    const [showConfigurationDialog, setShowConfigurationDialog] = useState(false);
    const workspacePath = generateWorkspacePath();
    const configPath = `${workspacePath}/configurations?type=APIAccess`

    const resetAll = () => {
        setName('');
        setSecured(true);
        setActive(true);
        clearErrors();
        setHeaders([{ key: "", value: "" }]);
        setCaptureRemoteIP(false);
    }

    const clearErrors = () => {
        setError({ message: null, details: [] });
    }

    const onConfigurationSelection = (item) => {
        setConfiguration(item);
        setShowConfigurationSelector(false);
    }


    const onCreateDestination = (item) => {
        setConfiguration(item);
        setShowConfigurationSelector(false)
    }

    const submit = (e) => {
        e.preventDefault();
        clearErrors();
        setExecuting(true);

        if (!name) {
            addNotification({
                message: 'Enter Source Name',
                type: 'error'
            });
            setExecuting(false);
            return;
        }

        const configurationId = configuration?.id || ''

        const payload = {
            name,
            secured,
            active,
            headers: filter(headers, item => item.key),
            captureRemoteIP,
            apiAccessRef: configurationId
        };

        axios.post(`${workspacePath}/sources`, payload).then(response => {
            resetAll();
            addNotification({
                message: 'Source has been created',
                type: 'success'
            });
            onSuccess(response.data);
        }).catch(err => {
            addNotification({
                message: get(err, 'response.data.message', 'An error occurred while creating Source'),
                type: 'error'
            });
            setError(err.response.data);
        }).finally(() => {
            setExecuting(false);
        })
    };

    return <Fragment>

        <form onSubmit={submit}>
            <div className={`sm:overflow-hidden sm:rounded-md ${noShadow ? '' : 'shadow-inner'}`}>
                <div className="space-y-3 px-4 py-5 sm:p-6">

                    <div className="form-control w-full">
                        <label className="label">
                            <span className="label-text">Source Name</span>
                        </label>
                        <input type="text" placeholder="Provide a name for the Source" value={name} className="input input-bordered w-full" onChange={e => setName(e.target.value)} />
                        {renderErrors(error, 'name')}
                    </div>
                    <div className="form-control w-full">
                        <label className="label">
                            <span className="label-text">Response Headers</span>
                        </label>
                        <HeaderBuilder headers={headers} setHeaders={setHeaders} />
                        {renderErrors(error, 'config')}
                    </div>
                    <div className="form-control w-full">
                        <label className="label">
                            <span className="label-text">Secured</span>
                        </label>
                        <input type="checkbox" className={`toggle toggle-lg ${secured ? 'toggle-success' : ''}`} checked={secured} onChange={(e) => setSecured(e.target.checked)} />
                        {renderErrors(error, 'secured')}
                    </div>
                    <div className="form-control w-full">
                        <label className="label">
                            <span className="label-text">Select a Configuration</span>
                        </label>
                        <div className="input-group">
                            <input type="text" readOnly disabled placeholder="Select" value={configuration?.name || ''} className="input input-bordered" />
                            <button className="btn btn-square tooltip p-3" data-tip="Select a Configuration" onClick={e => { e.preventDefault(); setShowConfigurationSelector(true) }}>
                                <IconSearch width={24} />
                            </button>

                            <button className="btn btn-square tooltip p-2" data-tip="Create Configuration" onClick={e => { e.preventDefault(); setShowConfigurationDialog(true) }}>
                                <IconPlus width={24} />
                            </button>

                        </div>
                        {renderErrors(error, 'confihuration')}
                    </div>
                    <div className="form-control w-full">
                        <label className="label">
                            <span className="label-text">Active</span>
                        </label>
                        <input type="checkbox" className={`toggle toggle-lg ${active ? 'toggle-success' : ''}`} checked={active} onChange={(e) => setActive(e.target.checked)} />
                        {renderErrors(error, 'active')}
                    </div>
                    <div className="form-control w-full">
                        <label className="label">
                            <span className="label-text">Capture Remote IP</span>
                        </label>
                        <input type="checkbox" className={`toggle toggle-lg ${active ? 'toggle-success' : ''}`} checked={captureRemoteIP} onChange={(e) => setCaptureRemoteIP(e.target.checked)} />
                        {renderErrors(error, 'captureRemoteIP')}
                    </div>

                </div>
                <div className="bg-base-200 px-4 py-3 text-right sm:px-6">
                    <button onClick={onCancel} className="btn btn-ghost mr-2">Cancel</button>
                    <button disabled={executing} type="submit" className="btn btn-primary">Submit</button>
                </div>
            </div>
        </form>
        {showConfigurationSelector && <ItemSelectorModal url={configPath} title="Select a Configuration" cols={[{ "label": "Name", "datacss": "text-left", "css": "w-1/2", "name": "name" }, { "label": "Type", "datacss": "", "css": "w-1/2", "name": "type" }]}
            onCancel={() => setShowConfigurationSelector(false)} onSelect={onConfigurationSelection} />}
        {showConfigurationDialog && <Modal title="Create Configuration" onCancel={() => setShowConfigurationDialog(false)}>
            <NewConfiguration noShadow={true} onSuccess={onCreateDestination} onCancel={() => setShowConfigurationDialog(false)} /></Modal>}
    </Fragment>

}

export default CreateSource;