module.exports = {

    modScriptTemplate:
        `module myplugin

// currently the backend only supports go v1.22
go 1.22
`,
    mainScriptTemplate:
        `
package main

import (
	"encoding/json"
	"fmt"
)

// this can be defined by you based
// on your event structure
type Event struct {
	Sub       string
	Processed bool
}

// Run function parameters
//
//  1. config - key value pair configured in the transformation
//  2. event - your event data as json string. you need to manualy 
//             unmarshal it into appropriate struct.
//  3. vars - these are global variable and can be accessed by all 
//            transformations/destination in the given pipeline.
//
// Returns
//
//  1. the modified event. pipeline will fail if it is not returned.
//  2. vars - the global pariable passed in this fuction.
//  3. error

func Run(
	config map[string]string,
	event string,
	vars map[string]string,
) (string, map[string]string, error) {
	myEvent := &Event{}
	err := json.Unmarshal([]byte(event), myEvent)
	if err != nil {
		return event, vars, err
	}
	return event, vars, nil
}


`,
    mainScriptDestinationTemplate:
        `
package main

import (
	"encoding/json"
	"fmt"
	"log"
)

func Setup(config map[string]string) error { /* optional */
        //setup connection
	return nil
}

func Teardown(config map[string]string) error {/* optional */
        //teardown connection
	return nil
}

func Execute(config map[string]string, event string, vars map[string]string) (map[string]string, error) {/* required */
        //send to destination.
	return vars, nil
}
`

}
