export const generateWorkspacePath = () => {
    let selectedWorkspace = '';

    if(localStorage.getItem('workspace-id')){
        selectedWorkspace = localStorage.getItem("workspace-id") || ''
    }
    return `/mgmt-srv/workspace/${selectedWorkspace}`;

}

export function setSelectedWorkspace(id, name){
    localStorage.setItem('workspace-id',id)
    localStorage.setItem('workspace-name', name)
}
