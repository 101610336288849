import { useState, useRef } from "react";

import axios from "axios";
import useNotifications from "../../../hooks/useNotifications";
import get from 'lodash/get';
import { renderErrors } from "../../../common/formHelpers";
import Editor from '@monaco-editor/react'
import ConfigBuilder from "../../../components/ConfigBuilder";
import filter from 'lodash/filter';
import Modal from "../../../components/Modal";
import { generateWorkspacePath } from "../../../common/urlHelpers";
import ScriptLanguageSelector from "../../../components/ScriptLanguageSelector";
import { mainScriptDestinationTemplate, modScriptTemplate } from "../../../common/scripts";


const scriptTemplate = `module.exports = {
    setup: function(config) { /* optional */
        //setup connection
    },
    execute: function(event, ctx, config) { /* required */
        //send to destination.
    },
    teardown: function(config) { /* optional */
        //teardown connection
    }
}`;


const CreateDestination = (props) => {
    const { onSuccess, onCancel, noShadow = false } = props;
    const editorRef = useRef(null);
    const { addNotification } = useNotifications();
    const [name, setName] = useState('');
    const [config, setConfig] = useState([{ key: "", value: "", secret: false }]);
    const [script, setScript] = useState(scriptTemplate);
    const [modScript, setModScript] = useState(modScriptTemplate);
    const [mainScript, setMainScript] = useState(mainScriptDestinationTemplate);
    const [active, setActive] = useState(true);
    const [executing, setExecuting] = useState(false);
    const [error, setError] = useState({ message: null, details: [] });
    const [expandEditor, setExpandEditor] = useState(false);
    const [selectedLanguage, setSelectedLanguage] = useState('javascript');

    const workspacePath = generateWorkspacePath();

    const resetAll = () => {
        setName('');
        setScript(scriptTemplate);
        setMainScript(mainScriptDestinationTemplate);
        setModScript(modScriptTemplate);
        setActive(true);
        clearErrors();
    }

    const clearErrors = () => {
        setError({ message: null, details: [] });
    }

    const submit = (e) => {
        e.preventDefault();
        clearErrors();
        setExecuting(true);

        if (!name) {
            addNotification({
                message: 'Enter Destination Name',
                type: 'error'
            });
            setExecuting(false);
            return;
        }

        const payload = {
            name,
            active,
            config: filter(config, item => item.key),
            language: selectedLanguage === 'javascript' ? selectedLanguage : 'golang'
        };
        if (selectedLanguage === "Javascript" || selectedLanguage === "javascript") {
            payload.script = script;
        } else {
            payload.golang = {
                main: mainScript,
                mod: modScript
            }
        }
        axios.post(`${workspacePath}/destinations`, payload).then(response => {
            resetAll();
            addNotification({
                message: 'Destination has been created',
                type: 'success'
            });
            onSuccess(response.data);
        }).catch(err => {
            addNotification({
                message: get(err, 'response.data.message', 'An error occurred while creating Destination'),
                type: 'error'
            });
            setError(err.response.data);
        }).finally(() => {
            setExecuting(false);
        })
    };

    return <form onSubmit={submit}>
        <div className={`sm:overflow-hidden sm:rounded-md ${noShadow ? '' : 'shadow-inner'}`}>
            <div className="space-y-3 px-4 py-5 sm:p-6">

                <div className="form-control w-full">
                    <label className="label">
                        <span className="label-text">Destination Name</span>
                    </label>
                    <input type="text" placeholder="Provide a name for the Destination" value={name} className="input input-bordered w-full" onChange={e => setName(e.target.value)} />
                    {renderErrors(error, 'name')}
                </div>

                <div className="form-control w-full">
                    <label className="label">
                        <span className="label-text">Configuration</span>
                    </label>
                    <ConfigBuilder config={config} setConfig={setConfig} />
                    {renderErrors(error, 'config')}
                </div>

                <ScriptLanguageSelector
                    script={script}
                    setScript={setScript}
                    mainScript={mainScript}
                    setMainScript={setMainScript}
                    modScript={modScript}
                    setModScript={setModScript}
                    expandEditor={expandEditor}
                    setExpandEditor={setExpandEditor}
                    error={error}
                    addNotification={addNotification}
                    urlPath="/destinations"
                    editMode={true}
                    selectedLanguage={selectedLanguage}
                    setSelectedLanguage={setSelectedLanguage}
                />

                <div className="form-control w-full">
                    <label className="label">
                        <span className="label-text">Active</span>
                    </label>
                    <input type="checkbox" className={`toggle toggle-lg ${active ? 'toggle-success' : ''}`} checked={active} onChange={(e) => setActive(e.target.checked)} />
                    {renderErrors(error, 'active')}
                </div>

            </div>
            <div className="bg-base-200 px-4 py-3 text-right sm:px-6">
                <button onClick={onCancel} className="btn btn-ghost mr-2">Cancel</button>
                <button disabled={executing} type="submit" className="btn btn-primary">Submit</button>
            </div>
        </div>
        {expandEditor && (
            <Modal large={true} title="Script" onCancel={() => setExpandEditor(false)}>
                <>
                    <div className="px-6 pb-4">

                        <ScriptLanguageSelector
                            script={script}
                            setScript={setScript}
                            mainScript={mainScript}
                            setMainScript={setMainScript}
                            modScript={modScript}
                            setModScript={setModScript}
                            expandEditor={expandEditor}
                            setExpandEditor={setExpandEditor}
                            error={error}
                            addNotification={addNotification}
                            workspacePath={workspacePath}
                            urlPath="/destinations"
                            editMode={true}
                            selectedLanguage={selectedLanguage}
                            setSelectedLanguage={setSelectedLanguage}

                        />

                    </div>
                    <div className="bg-base-200 px-4 py-3 justify-end sm:px-6 flex">
                        <button className="btn" onClick={() => setExpandEditor(false)}>Collapse</button>
                    </div>
                </>
            </Modal>
        )}

    </form>
}

export default CreateDestination;