
import { useEffect } from 'react';

const RedirectToApp = (props) => {
    useEffect(() => {
        window.location.replace(props.to)
    }, [])
    return <div>Redirecting...</div>
}

export default RedirectToApp;
