import * as CidaasSDK from 'cidaas-javascript-sdk';
import axios from "axios"

var cidaasSdk;

const InitializeCidaas = function (issuer, client_id) {
  let options = {
    authority: issuer,
    client_id: client_id,
    redirect_uri: window.location.origin + "/app/home",
    response_type: 'code',
    response_mode: 'query',
    scope: 'profile',
    post_logout_redirect_uri: window.location.origin + "/app/home"
  };
  cidaasSdk = new CidaasSDK.WebAuth(options);
  axios.defaults.baseURL = window.location.origin;
}

const CheckForToken = function () {
  return new Promise((resolve, reject) => {
    let token = localStorage.getItem("access_token");

    if (!token) {
      cidaasSdk.loginCallback().then((res) => {
        localStorage.setItem("access_token", res.access_token);
        axios.defaults.headers.common['Authorization'] = "Bearer " + res.access_token;
        window.location.reload();
        resolve(false); // Token was just set, need to reload
      }).catch((err) => {
        cidaasSdk.loginWithBrowser();
        resolve(false); // Redirecting to login
      });
    } else {
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      const options = {
        token: token,
        token_type_hint: "access_token"
      };
      cidaasSdk.validateAccessToken(options).then((response) => {
        if (((response && response.error) || (response && response.active === false))) {
          localStorage.removeItem("access_token");
          cidaasSdk.loginCallback().then((res) => {
            localStorage.setItem("access_token", res.access_token);
            axios.defaults.headers.common['Authorization'] = "Bearer " + res.access_token;
            resolve(false); // Token was just set, need to reload
          }).catch((err) => {
            cidaasSdk.loginWithBrowser();
            window.location.href = window.location.origin + "/app";
            resolve(false); // Redirecting to login
          });
        } else {
          resolve(true); // Token is valid
        }
      }).catch(err => {
        reject(err);
      });
    }
  });
}

const Logout = () => {
  var token = localStorage.getItem('access_token');
  localStorage.clear();
  sessionStorage.clear();
  cidaasSdk.logoutUser({ access_token: token });
}

export {
  InitializeCidaas,
  CheckForToken,
  Logout
};
