import React, { memo } from "react";
import { Handle, Position } from "reactflow";

export default memo(({ data, isConnectable }) => {
  return (
    <div style={{ textAlign: 'center', position: 'relative', padding: '10px', border: '1px solid black', borderRadius: '4px' }}>
      <img src="/decision-making.svg" alt="node" style={{ width: '50px', height: '50px', borderRadius: '4px' }} />
      <Handle
        type="target"
        position="left"
        style={{
          width: '10px',
          height: '10px',
        }}
        isConnectable={isConnectable}
      />
      <Handle
        type="source"
        position={Position.Right}
        id="true"
        style={{
          top: 20, width: '10px',
          height: '10px',
        }}
        isConnectable={isConnectable}

      ><div className="handletext">1</div></Handle>
      <Handle
        type="source"
        position={Position.Right}
        id="false"
        style={{
          bottom: 10, width: '10px',
          height: '10px', top: "auto"
        }}
        isConnectable={isConnectable}
      ><div className="handletext">0</div></Handle>
      <div style={{
        marginTop: '15px', textAlign: 'center', marginRight: '25px',
        position: 'fixed'
      }}>{data.label}</div>
    </div>
  );
});

// Styles for the custom node
const styles = {

};

